<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import { Nullable } from '@algorh/shared'
  import { AlgButton, AlgIllustration, AlgModal, AlgTabs, Tab } from '@algorh/ui'

  import { UserInfos } from '@/components'
  import { useAppInit, useConfigs } from '@/composables'
  import { UserPermissions } from '@/core/enums'

  import AccountInfos from './partials/AccountInfos.vue'
  import AccountSecurity from './partials/AccountSecurity.vue'
  import AccountStatistics from './partials/AccountStatistics.vue'

  type TabList = 'infos' | 'security' | 'statistics'

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  // Composables
  const router = useRouter()

  const { t } = useI18n()

  // Data
  const { permissions, me } = useAppInit()

  const { authConfig, supportEnabled } = useConfigs()

  const tab = ref<Nullable<TabList>>(null)

  async function handleTabUpdate(t: typeof tab.value) {
    await router.push({
      name: router.currentRoute.value.name,
      params: router.currentRoute.value.params,
      query: t !== null ? { tab: t, ...router.currentRoute.value.query } : router.currentRoute.value.query,
    })
  }

  // Computed
  const tabs = computed((): Tab<TabList>[] => [
    {
      id: 'infos',
      label: t('account.My info'),
    },
    {
      id: 'security',
      label: t('account.My security'),
      displayed: !authConfig.value?.authentication_system,
    },
    {
      id: 'statistics',
      label: t('account.My statistics'),
      displayed: permissions.value?.[UserPermissions.ACCESS_CALENDAR],
    },
  ])

  function handleClose() {
    emit('close')
  }

  async function handleContactUs() {
    await router.push('/support')
  }
</script>

<template>
  <AlgModal
    name="account"
    size="xl"
    :footer="false"
    @close="handleClose"
  >
    <template #title>
      <UserInfos :user="me?.data" />
    </template>
    <template #subheader>
      <AlgTabs
        v-model="tab"
        :tabs="tabs"
        @update:model-value="handleTabUpdate"
      />
    </template>
    <template #content>
      <div class="content-wrapper">
        <div class="account-sections">
          <AccountInfos v-if="tab === 'infos'" />
          <AccountSecurity v-if="tab === 'security'" />
          <AccountStatistics v-if="tab === 'statistics'" />
        </div>
        <div
          v-if="supportEnabled"
          class="account-footer"
        >
          <AlgIllustration
            name="support"
            :size="40"
          />
          <p>
            {{ t('support.Do you have a problem or a question?') }}
          </p>
          <AlgButton
            size="s"
            :label="t('support.Contact us')"
            @click="handleContactUs"
          />
        </div>
      </div>
    </template>
  </AlgModal>
</template>

<style scoped>
  .account-sections {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .content-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;

    .account-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: var(--alg-spacing-l) 0;
      border-top: 1px solid var(--alg-color-surface-border);
      gap: var(--alg-spacing-m);
      text-align: center;
    }
  }
</style>
