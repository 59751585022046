import { createApp, Fragment, h } from 'vue'
import * as Sentry from '@sentry/vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { createPinia } from 'pinia'

import '@algorh/ui/src/index.css'
import './css/app.css'

import { version } from '../../../package.json'

import App from './App.vue'
import { i18n, queryClient } from './plugins'
import { router } from './router'

let app = null

if (import.meta.env.DEV) {
  // @ts-expect-error declaration file doens't work
  const VueAxe = await import('vue-axe')
  app = createApp({
    render: () => h(
      Fragment,
      [h(App), h(VueAxe.VueAxePopup)],
    ),
  })
  app.use(VueAxe.default)
} else {
  app = createApp(App)
}

const pinia = createPinia()

const vueRouter = router()

app.use(pinia)
app.use(vueRouter)
app.use(i18n)

app.use(VueQueryPlugin, { queryClient })

if (import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    release: `algorh@${version}`,
    maxValueLength: 1000,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    ignoreErrors: [
      /Non-Error exception captured/,
      /Failed to fetch/,
      /Object captured as promise rejection with keys: message, status/,
    ],
    tracesSampleRate: 0.0,
  })
}

app.mixin({ inheritAttrs: true }).mount('#app')
export { queryClient }
