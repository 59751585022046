<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useWindowSize } from '@vueuse/core'

  import { AlgIcon } from '@algorh/ui'

  import { Loader } from '@/components'
  import { useAppInit, useBreadcrumb, useConfigs } from '@/composables'

  import { NotificationHeaderDropdown } from '@/sections/notifications/components'

  import AccountModal from './account-modal/AccountModal.vue'
  import LayoutLogo from './LayoutLogo.vue'
  import NavigationMenu from './NavigationMenu.vue'

  // Composables
  const { t } = useI18n()

  const { breadcrumb } = useBreadcrumb()

  const { width } = useWindowSize()

  const { permissions } = useAppInit()

  const { notificationsEnabled, isLoadingConfig } = useConfigs()

  // Refs
  const displayAccountModal = ref<boolean>(false)

  const isMenuOpen = ref(false)

  // Methods
  function toggleMenu() {
    isMenuOpen.value = !isMenuOpen.value
  }

  onMounted(() => {
    if (width.value > 720) {
      isMenuOpen.value = true
    }
  })
</script>

<template>
  <div class="layout">
    <NavigationMenu
      class="navigation"
      :class="{open: isMenuOpen}"
      @open-account-modal="displayAccountModal = true"
      @close="toggleMenu"
    />
    <div class="layout-content">
      <header class="layout-header">
        <LayoutLogo
          class="layout-header-logo"
          :class="{'menu-open': isMenuOpen}"
        />
        <button
          class="menu-button"
          type="button"
          :aria-label="isMenuOpen ? t('common.Close menu') : t('common.Open menu')"
          @click="toggleMenu"
        >
          <AlgIcon
            name="burger-menu"
            size="s"
          />
        </button>
        <div
          class="breadcrumb"
          :class="{'menu-open': isMenuOpen}"
        >
          <template
            v-for="(item, k) in breadcrumb"
            :key="k"
          >
            <span
              v-if="item.inactive"
              class="link inactive"
            >
              <span class="link-content">
                {{ item.label }}
              </span>
              <AlgIcon
                v-if="k < breadcrumb.length - 1"
                name="chevron-right"
                size="s"
              />
            </span>
            <RouterLink
              v-else
              :to="item"
              class="link"
              :class="{'active': k === breadcrumb.length - 1}"
            >
              <span class="link-content">
                {{ item.label }}
              </span>
              <AlgIcon
                v-if="k < breadcrumb.length - 1"
                name="chevron-right"
                size="s"
              />
            </RouterLink>
          </template>
        </div>
        <div
          v-if="(permissions?.access_calendar || permissions?.access_training) && notificationsEnabled"
          class="notifications"
        >
          <NotificationHeaderDropdown />
        </div>
      </header>
      <template v-if="isLoadingConfig">
        <div class="loader">
          <div class="navigation" />
          <div class="loader-content">
            <Loader />
          </div>
        </div>
      </template>
      <main
        v-else
        class="layout-body"
        :class="{'menu-open': isMenuOpen}"
      >
        <slot />
      </main>
    </div>
    <AccountModal
      v-if="displayAccountModal"
      @close="displayAccountModal = false"
    />
  </div>
</template>

<style scoped>
  .loader {
    display: flex;
    min-width: 0;
    min-height: 0;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    background-color: var(--alg-color-surface-secondary);

    .loader-nav {
      position: absolute;
      z-index: 10;
      background-color: var(--alg-color-surface-primary);
      inset: 0;
    }
  }

  .layout {
    display: flex;
    min-width: 0;
    min-height: 0;
    flex: 1 1 auto;
    background-color: var(--alg-color-surface-secondary);

    --menu-width: 100%;

    @media screen and (width >= 720px) {
      --menu-width: 240px;
    }

    @media screen and (width >=1920px) {
      --menu-width: 300px;
    }

    .navigation {
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 0;
      left: calc(var(--menu-width) * -1);
      width: var(--menu-width);
      transition: ease-in-out 250ms left, ease-in-out 250ms width;

      &.open {
        left:0;
      }

    }

    .layout-content {
      position: relative;
      display: flex;
      min-width: 0;
      min-height: 0;
      flex: 1 1 auto;
      flex-direction: column;
      overflow-y: auto;
      transition: all 0.3s ease;

      .layout-header {
        position: sticky;
        z-index: 20;
        top: 0;
        display: flex;
        width: 100%;
        height: 64px;
        box-sizing: border-box;
        padding: var(--alg-spacing-m) 0;

        .layout-header-logo {
          overflow: hidden;
          width: 60px;
          height: 100%;
          box-sizing: border-box;
          flex: 0 0 auto;
          padding-left: var(--alg-spacing-m);
          transition: ease-in-out 250ms width;

          &.menu-open {
            width: calc(var(--menu-width) - 40px);
          }

          @media screen and (width >= 720px) {
            width: 180px;

            &.menu-open {
              width: calc(var(--menu-width) - 60px);
            }
          }
        }

        .menu-button {
          display: flex;
          width: 40px;
          flex: 0 0 auto;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding-right: var(--alg-spacing-m);
          color: var(--alg-color-text-secondary);
          transition-duration: var(--alg-transition-colors-duration);
          transition-property: color;
          transition-timing-function: var(--alg-transition-colors-timing-function);

          &:hover {
            color: var(--alg-color-text-primary);
          }

          @media screen and (width >= 720px) {
            width: 60px;
          }
        }

        .breadcrumb {
          display: flex;
          overflow: hidden;
          flex: 0 1 auto;
          flex-direction: row;
          align-items: center;
          text-overflow: ellipsis;
          transition: ease-in-out 250ms padding-left;
          white-space: nowrap;

          &.menu-open{
            padding-left: var(--alg-spacing-m);

            @media screen and (width >= 720px) {
              padding-left: var(--alg-spacing-l);
            }
          }

          .link {
            display: flex;
            overflow: hidden;
            flex: 0 0 auto;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: var(--alg-color-text-secondary);
            font-size: var(--alg-font-size-s);
            font-weight: var(--alg-font-weight-regular);
            text-overflow: ellipsis;
            transition: color ease-in-out 150ms;
            white-space: nowrap;

            &:hover,
            &.active {
              color: var(--alg-color-text-primary);
            }

            &.inactive {
              color: var(--alg-color-text-secondary);
            }

            &:last-child {
              display: inline-block;
              flex: 1 1 auto;
            }
          }
        }

        .notifications {
          flex: 0 0 auto;
          margin: 0 var(--alg-spacing-m) 0 auto;
        }
      }

      .layout-body {
        display: flex;
        min-height: 0;
        box-sizing: border-box;
        flex: 1 1 auto;
        padding: 0;
        overflow-y: auto;
        transition: ease-in-out 250ms padding-left;

        &.menu-open{
          padding-left: calc(var(--menu-width) + var(--alg-spacing-m));
        }

        @media screen and (width >= 720px) {
          padding: 0 var(--alg-spacing-l) var(--alg-spacing-l) var(--alg-spacing-l);

          &.menu-open{
            padding-left: calc(var(--menu-width) + var(--alg-spacing-l));
          }
        }
      }
    }
  }
</style>
